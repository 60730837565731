import React, { Component } from "react";
import MyContext from "../context/myContext";
import { logIn, changeLang } from "../../reducers/actions/index";
import Translation from "../../components/translation/translation";
import "./register.scss";
import "../../styles/shared.scss";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MTCaptcha from "../../components/mtcaptcha/mtcaptcha";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import MaskedInput from "react-text-mask";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { navigate } from "gatsby";
import { connect } from "react-redux";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class RegisterPage extends Component {
  session;
  script =
    "var mtcaptchaConfig = {'sitekey': 'MTPublic-nQ5yaXtpk'};(function(){var mt_service = document.createElement('script');mt_service.async = true;mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);var mt_service2 = document.createElement('script');mt_service2.async = true;mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);}) ();";
  constructor(props, context) {
    super(props, context);
    this.state = {
      client: this.context.sdkClient,
      confirmPasswordError: undefined,
      confirmEmailError: undefined,
      nissError: undefined,
      contactMailError: undefined,
      countryCode: "+32",
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
    const receiveMessage = event => {
      if (event.origin === "https://service.mtcaptcha.com") {
        try {
          const data = JSON.parse(event.data);
          if (data.eventinfo.msg === "Verified") {
            this.setState({ captchaValid: true });
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
    }
  }

  render() {
    return (
      <div className="auth_page">
        <div className="titles">
          <span
            className="back"
            onClick={() =>
              navigate("/how", {
                state: { optinAccepted: true, lang: this.props.lang },
              })
            }
          >
            <ArrowBackIosIcon />
            <Translation trl="back_how" />
          </span>
          <span
            className="back_mobile"
            onClick={() =>
              navigate("/how", {
                state: { optinAccepted: true, lang: this.props.lang },
              })
            }
          >
            <ArrowBackIosIcon />
            <Translation trl="back" />
          </span>
          <h1>
            <Translation trl="register_main_title" />
          </h1>
          {/* <p><Translation trl="register_title"/></p> */}
          <h2>
            <Translation trl="fill_form" />
          </h2>
        </div>
        <form>
          <h3>
            <Translation trl="coord_title" />
          </h3>
          <div
            className={
              this.state.mailError
                ? "form_input error"
                : this.state.mailError === false
                ? "form_input success"
                : "form_input"
            }
          >
            <label>
              <Translation trl="cardId" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              placeholder="XX.XX.XX-XXX.XX"
              value={this.state.idNumber}
              onChange={e => this.changeNiss(e.target.value)}
              onBlur={e => this.checkNISS(e.target.value)}
            />
          </div>
          <div
            className={
              this.state.pwdError
                ? "form_input half error"
                : this.state.pwdError === false
                ? "form_input half success"
                : "form_input half"
            }
          >
            <label>
              <div>
                <Translation trl="pwd" />
                <br />(
                <span
                  className={this.state.pwdMinLengthError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_min_length" />
                </span>
                ,{" "}
                <span
                  className={this.state.pwdAlphaNumError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_alpha" />
                </span>
                ,{" "}
                <span className={this.state.pwdMixCaseError ? "pwd_error" : ""}>
                  <Translation trl="pwd_mix_case" />
                </span>{" "}
                <span
                  className={this.state.pwdSpecialCharError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_special" />
                </span>
                )
              </div>
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="**********"
              value={this.state.password}
              onChange={e => this.handlePasswordChange(e.target.value)}
              onBlur={e => this.checkPassword(e.target.value)}
            />
            {!this.state.showPassword && (
              <VisibilityIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: true })}
              />
            )}
            {this.state.showPassword && (
              <VisibilityOffIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: false })}
              />
            )}
          </div>
          <div
            className={
              this.state.confirmPasswordError
                ? "form_input half error"
                : this.state.confirmPasswordError === false
                ? "form_input half success"
                : "form_input half"
            }
          >
            <label>
              <Translation trl="conf_pwd" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              type={this.state.showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="**********"
              value={this.state.confirmPassword}
              onChange={e => this.setState({ confirmPassword: e.target.value })}
              onBlur={e => this.checkConfirmPassword(e.target.value)}
            />
            {!this.state.showConfirmPassword && (
              <VisibilityIcon
                className="pwd_icon"
                onClick={() => this.setState({ showConfirmPassword: true })}
              />
            )}
            {this.state.showConfirmPassword && (
              <VisibilityOffIcon
                className="pwd_icon"
                onClick={() => this.setState({ showConfirmPassword: false })}
              />
            )}
          </div>
          <div className="form_input half">
            <label>
              <Translation trl="firstname" />
            </label>
            <input
              placeholder="John"
              value={this.state.firstName}
              onChange={e => this.setState({ firstName: e.target.value })}
            />
          </div>
          <div className="form_input half">
            <label>
              <Translation trl="lastname" />
            </label>
            <input
              placeholder="Smith"
              value={this.state.lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
            />
          </div>
          <h3>
            <Translation trl="coord_contact" />
          </h3>
          <div
            className={
              this.state.contactMailError
                ? "form_input half error"
                : this.state.contactMailError === false
                ? "form_input half success"
                : "form_input half"
            }
          >
            <label>
              <Translation trl="mail" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              autoComplete="username"
              placeholder="john.smith@mail.com"
              value={this.state.mail}
              onChange={e => this.setState({ mail: e.target.value })}
              onBlur={e => this.checkMail(e.target.value)}
            />
          </div>

          <div
            className={
              this.state.phoneError
                ? "form_input half error"
                : this.state.phoneError === false
                ? "form_input half success"
                : "form_input half"
            }
          >
            <label>
              <Translation trl="phone" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <div className="phone">
              <input
                className="countryCode"
                type="text"
                value={this.state.countryCode}
                disabled
              />
              <input
                type="text"
                value={this.state.phone}
                placeholder="4XXXXXXXX"
                onChange={e => this.setState({ phone: e.target.value })}
                onBlur={e => this.checkPhone(e.target.value)}
              />
            </div>
            <div style={{
                          display: "block", marginTop: "5px"
                        }}>
            <label style={{
                          display: "block",
                        }}>
            <Translation trl="how_text1a" /> (<a className="govapp_link" href="https://apps.apple.com/us/app/govapp/id1620323239" target="_blank">App Store</a> - <a className="govapp_link" href="https://play.google.com/store/apps/details?id=be.fgov.govapp" target="_blank">Google play</a>)
            </label>
            </div>
          </div>


          {/* 	<div className={'form_input half'}>
								<label><Translation trl="zipCode"/></label>
								<input type="text" placeholder="XXXX" value={this.state.zipCode} onChange={(e) => this.setZipCode(e.target.value)}/>
						</div> */}
          <div className={"form_input half check"}>
            <Checkbox
              onChange={e => this.setState({ optin1Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="checkbox1" />
            </label>
          </div>
          <div className={"form_input half check"}>
            <Checkbox
              onChange={e => this.setState({ optin2Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label className="checkbox">
              <Translation trl="checkbox2_1" />
              <a
                href={
                  this.props.lang === "nl"
                    ? `../../NL - Algemene gebruiksvoorwaarden van een website QVAX.pdf`
                    : this.props.lang === "de"
                    ? `../../QVAX-Allgemeine_Bedingungen_für_die_Nutzung.pdf`
                    : this.props.lang === "en"
                    ? `../../EN-Website_general_terms_and_conditions_QVAX.pdf`
                    : `../../FR - Conditions générales d'utilisation d'un site internet QVAX.pdf`
                }
                target="_blank"
              >
                <Translation trl="cgu" />
              </a>
              <Translation trl="checkbox2_2" />
              <a
                href={
                  this.props.lang === "nl"
                    ? `../../NL_QVAX_Privacy_Policy_021221.pdf`
                    : this.props.lang === "en"
                    ? `../../EN_QVAX_Privacy_Policy_021221.pdf`
                    : this.props.lang === "de"
                    ? `../../DE-QVAX-Datenschutzregelung.pdf`
                    : `../../FR_QVAX_Politique_de_confidentialité_021221.pdf`
                }
                target="_blank"
              >
                <Translation trl="privacy" />
              </a>
              <Translation trl="checkbox2_3" />
            </label>
          </div>

          {/* captcha only for production */}
          {(STRS_ENV === "production" || STRS_ENV === "development") && (
            <div
              className={
                this.state.captchaError
                  ? "form_input half error"
                  : this.state.captchaError === false
                  ? "form_input half success"
                  : "form_input half"
              }
            >
              <label>
                <Translation trl="robot" />
              </label>
              <MTCaptcha onVerified={() => this.validateCaptcha()} />
            </div>
          )}
        </form>

        {this.state.signupErrors && (
          <div className="errors">
            {this.state.signupErrors.map((error, index) => (
              <span key={index}>
                <Translation trl={error} />
              </span>
            ))}
          </div>
        )}

        <div className="actions">
          <button
            id="nextStepRegister"
            className={this.isFormValid() ? "primary" : "primary disabled"}
            onClick={e => this.onSubmit(e)}
          >
            <span>
              <Translation trl="signup_button" />
            </span>
          </button>
        </div>
      </div>
    );
  }

  formatNiss(value) {
    const numbers = value.replace(/\D/g, "");
    const numb = numbers.split("");
    let inserted = 0;
    numbers.split("").forEach((n, i) => {
      if (i === 2 || i === 4 || i === 9) {
        numb.splice(i + inserted, 0, ".");
        inserted += 1;
      }
      if (i === 6) {
        numb.splice(i + inserted, 0, "-");
        inserted += 1;
      }
    });
    return numb.join("");
  }

  changeNiss(value) {
    const lastChar = value[value.length - 1];
    const reg = /^\d+$/;
    if (
      value.length <= 15 &&
      reg.test(lastChar) &&
      (!this.state.idNumber || value.length >= this.state.idNumber.length)
    ) {
      this.setState({ idNumber: this.formatNiss(value), mailError: undefined });
      if (value.length === 15) {
        this.checkNISS(value);
      }
    } else {
      if (value.length === 0) {
        this.setState({ idNumber: undefined });
      } else {
        if (this.state.idNumber && value.length < this.state.idNumber.length) {
          this.setState({ idNumber: value, mailError: undefined });
        } else {
          this.setState({ idNumber: this.state.idNumber });
        }
      }
    }
  }

  goBack() {
    if (this.isClientSide()) {
      localStorage.removeItem("qvax-selected-region");
      navigate("/region", { state: { lang: this.props.lang } });
    }
  }

  validateCaptcha() {
    this.setState({ captchaValid: true });
  }

  checkPhone(value) {
    if (
      (value.length > 0 && value.substring(0, 1) !== "4") ||
      (value.length > 0 && value.length !== 9)
    ) {
      this.setState({ phoneError: true });
    } else {
      if (value.length > 0) {
        this.setState({ phoneError: false });
      } else {
        this.setState({ phoneError: undefined });
      }
    }
  }

  checkNISS(value) {
    if (value.length === 15) {
      const niss1 = +value
        .replace(/\./g, "")
        .replace("-", "")
        .substring(0, 9);
      const niss2 = +(
        "2" +
        value
          .replace(/\./g, "")
          .replace("-", "")
          .substring(0, 9)
      );
      const verif = +value.substring(13);
      if (97 - (niss1 % 97) === verif || 97 - (niss2 % 97) === verif) {
        this.setState({ mailError: false });
      } else {
        this.setState({ mailError: true });
      }
    } else {
      if (value.length > 0) {
        this.setState({ mailError: true });
      } else {
        this.setState({ mailError: undefined });
      }
    }
  }

  setZipCode(value) {
    const reg = /^\d+$/;
    if ((value.length <= 4 && reg.test(value)) || !value) {
      this.setState({ zipCode: value });
    } else {
      this.setState({ zipCode: this.state.zipCode });
    }
  }

  setCardId(value) {
    const reg = /^\d+$/;
    if (value.length <= 15 || !value) {
      this.setState({ idNumber: value });
    } else {
      this.setState({ idNumber: this.state.idNumber });
    }
  }

  checkMail(value) {
    if (value.length > 0) {
      const mail = value;
      const mailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const isMatching = mailPattern.test(mail);
      this.setState({ contactMailError: !isMatching && mail.length > 0 });
    } else {
      this.setState({ contactMailError: undefined });
    }
  }

  checkConfirmMail(value) {
    if (value.length > 0) {
      if (this.state.mail !== value) {
        this.setState({ confirmEmailError: true });
      } else {
        this.setState({ confirmEmailError: false });
      }
    } else {
      this.setState({ confirmEmailError: undefined });
    }
  }

  checkPassword(password) {
    if (password.length > 0) {
      const pwdMinLengthError = !this.hasMinLength(password);
      const pwdMixCaseError = !this.hasMixCase(password);
      const pwdAlphaNumError = !this.hasAlphaNumeric(password);
      const pwdSpecialCharError = !this.hasSpecialChar(password);
      const pwdError =
        pwdMinLengthError ||
        pwdMixCaseError ||
        pwdAlphaNumError ||
        pwdSpecialCharError;
      this.setState({
        password: password,
        pwdError,
        pwdMinLengthError,
        pwdMixCaseError,
        pwdAlphaNumError,
        pwdSpecialCharError,
      });
    } else {
      this.setState({ pwdError: undefined });
    }
  }

  checkConfirmPassword(value) {
    if (value.length > 0) {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ confirmPasswordError: true });
      } else {
        this.setState({ confirmPasswordError: false });
      }
    } else {
      this.setState({ confirmPasswordError: undefined });
    }
  }

  isFormValid() {
    let formValues =
      this.state.firstName &&
      this.state.lastName &&
      this.state.mail &&
      this.state.password &&
      this.state.confirmPassword &&
      !this.state.confirmPasswordError &&
      this.state.idNumber &&
      !this.state.mailError &&
      !this.state.phoneError &&
      this.state.optin1Checked &&
      this.state.optin2Checked &&
      !this.state.contactMailError;

    // Check captcha if environment is production
    return (STRS_ENV === "production" || STRS_ENV === "development")
      ? formValues && this.state.captchaValid
      : formValues;
  }

  hasMinLength = pwd => {
    return pwd.length >= 10;
  };

  hasMixCase = pwd => {
    return pwd !== pwd.toLowerCase() && pwd !== pwd.toUpperCase();
  };

  hasAlphaNumeric = pwd => {
    const num = /\d/;
    const alpha = /.*[a-zA-Z]+.*/;

    return num.test(pwd) && alpha.test(pwd);
  };

  hasSpecialChar = pwd => {
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return format.test(pwd);
  };

  handleConfirmPasswordChange(e) {
    const password = e.target.value;
    this.setState({
      password2: password,
      pwdConfirmError: password !== this.state.password1,
    });
  }

  handlePasswordChange(password) {
    const pwdMinLengthError = !this.hasMinLength(password);
    const pwdMixCaseError = !this.hasMixCase(password);
    const pwdAlphaNumError = !this.hasAlphaNumeric(password);
    const pwdSpecialCharError = !this.hasSpecialChar(password);
    this.setState({
      password: password,
      pwdMinLengthError,
      pwdMixCaseError,
      pwdAlphaNumError,
      pwdSpecialCharError,
    });
  }

  onSubmit(event) {
    event.preventDefault();

    // Form object to send as payload
    const signupForm = {
      email: this.state.idNumber.replace(/\./g, "").replace("-", ""),
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      idNumber: this.state.idNumber.replace(/\./g, "").replace("-", ""),
      language: this.props.lang,
      contactEmail: this.state.mail,
      contactPhoneNumber: this.state.phone
        ? {
            countryCallingCode: "32",
            localNumber: this.state.phone,
          }
        : null,
      confirmationReturnURLPath: "https://qvax.be",
      registeredFromFanGroupId:
        FG_ID,
    };

    // Check captcha depending on environment (prod/dev) : captcha should be only on prod
    if (
      STRS_ENV === "production" &&
      typeof window !== "undefined"
    ) {
      const captchaToken = window.mtcaptcha.getVerifiedToken();
      signupForm.mtCaptchaToken = captchaToken;
    }

    // API call
    this.state.client.sessionService
      .doFormSignup(signupForm)
      .then(sess => {
        this.session = sess;
        if (this.isClientSide()) {
          localStorage.setItem(
            "SeatersEmbeddedAuthToken",
            JSON.stringify(this.session)
          );
          navigate("/", { state: { lang: this.props.lang } });
        }
        return this.state.client.fanService.fan();
      })
      /* .then(fan => {
				if (this.state.showSignupForm) {
					fan.personalInfo.birthDate = this.state.age.toISOString().split('T')[0];
					fan.personalInfo.idNumber = this.state.cardId;
					fan.mobilePhoneNumber = {
						countryCallingCode: '32',
						localNumber: this.state.phone.replace('/', '').substring(1)
					}
					return this.state.client.fanService.updateFan(fan);
				} else {
					return Promise.resolve(fan);
				}
			}) 
			.then(fan => {
				this.props.onLogged(this.session, fan, this.state.client);
			})*/
      .catch(e => {
        if (
          STRS_ENV === "production" &&
          typeof window !== "undefined"
        ) {
          window.mtcaptcha.resetUI();
        }
        if (e.errors && e.errors[0] && e.errors[0].message) {
          const error = JSON.parse(e.errors[0].message);
          if (
            error.errors &&
            error.errors.errorsTypes &&
            error.errors.errorsTypes.validation_errors
          ) {
            const errors = error.errors.errorsTypes.validation_errors.map(
              val => val.error.errorCode
            );
            let mailError = undefined;
            let confirmEmailError = undefined;
            let pwdError = undefined;
            let confirmPasswordError = undefined;
            let captchaError = undefined;
            if (errors.includes("api_authentication_signup_email_exists")) {
              mailError = true;
              confirmEmailError = true;
            }
            if (errors.includes("api_authentication_signup_invalid_captcha")) {
              captchaError = true;
            }
            if (errors.includes("api_authentication_signup_password_content")) {
              pwdError = true;
              confirmPasswordError = true;
            }
            this.setState({
              signupErrors: errors,
              captchaError,
              mailError,
              confirmEmailError,
              pwdError,
              confirmPasswordError,
            });
          }
        }
      });
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }

  isClientSide() {
    return typeof window !== "undefined";
  }
}

RegisterPage.contextType = MyContext;
const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang,
});

const mapDispatchToProps = () => {
  return {
    logIn,
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(RegisterPage);
