import React, { Component } from "react"
import ContextProvider from "../components/context/contextProvider"
import { createStore } from "redux"
import { Provider } from "react-redux"
import allReducer from "../reducers"
import "../styles/shared.scss"
import Cookies from "../components/cookies/cookies";
import MessageAlert from "../components/message-alert/message-alert";
import RegisterPage from "../components/register-page/registerPage"
import Navbar from "../components/navbar/Navbar"
import Footer from "../components/footer/footer"
import { navigate } from "gatsby"
import Helmet from "react-helmet"

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}

class Register extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const session = localStorage.getItem("SeatersEmbeddedAuthToken")
      if (!localStorage.getItem("qvax-selected-region")) {
        navigate("/region")
      }
      if (!localStorage.getItem("qvax-howitworks-accepted")) {
        navigate("/how")
      }
      if (session) {
        const parsedSession = JSON.parse(session)
        if (this.isSessionValid(parsedSession)) {
          navigate("/", { state: { lang: this.props.lang } })
        } else {
          localStorage.removeItem("SeatersEmbeddedAuthToken")
          navigate("/login")
        }
      }
    }
  }

  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <Helmet>
            <script
              type="text/javascript"
              src="//static.queue-it.net/script/queueclient.min.js"
            ></script>
            <script
              data-queueit-c="qvax"
              type="text/javascript"
              src="//static.queue-it.net/script/queueconfigloader.min.js"
            ></script>
          </Helmet>
          <Navbar />
          <MessageAlert />
          <Cookies />
          <RegisterPage
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
          <Footer />
        </Provider>
      </ContextProvider>
    )
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn)
    const dateNow = new Date()
    return expireDate > dateNow
  }
}
export default Register
